import * as React from "react";

import Puzzle from "../../Puzzle";

import kino from "../../images/kino.jpg";
import map from "../../images/m3.png";
import audio from "../../../static/a3.mp3";

const Page = () => {
  return (
    <Puzzle
      title="Riddle 3"
      author="Author: Iwona Kałuża"
      imageSrc={kino}
      imageAlt="szkic elewacji budynku"
      mapSrc={map}
      mapAlt="Punkt zaznaczony na mapie"
      // audio={audio}
      language="en"
      languageUrl={"/zagadki/uoGoOLQfo5"}
    >
      <div>
        <p>
          Let's go back to the end of the 1960s. Exactly in 1969, a technical
          project for the Warsaw cinema was created. It included an amphitheater
          auditorium with 650 seats, a comfortable waiting room, wide stairs, a
          recreation room and a buffet. Until the beginning of the 1990s, it was
          one of the most modern cinemas in Wrocław.
        </p>
        <p>
          Its architect, Andrzej Łukaszewicz, in cooperation with Mieczysław
          Rychlicki, built it on the former foundations of the 19th-century
          Palast-Theater. In the post-war building which they were rebuilding,
          the glass was supposed to appear on its facade.
        </p>
        <p>
          When outlining the context of glass as a material used in
          architecture, it must be explained how glass has evolved. From the
          brilliance of medieval stained glass windows, through glass mosaics
          and glass windows popular in the People's Republic of Poland, to the
          current glass skyscrapers. Glass was a synonym of modernity and
          prestige. “Where are your glass houses?” - asked Stefan Żeromski in
          his famous novel Spring to Come.
        </p>
        <p>
          In the Warsaw cinema, the facade was supposed to be decorated with a
          cladding made of melted waste glass. In order for everything to be
          consistent, the dimensions of the glass panes were adjusted to the
          size of the window openings. Numerous talks were held. It would seem
          that the plan was perfect – the project was supposed to utilize only
          unused materials. In the final stage, however, the management of the
          glassworks in Wałbrzych changed their mind, and the architects, as
          compensation, were offered the glass decoration of the screen covering
          the entrance. The glass designer from Wrocław, Zbigniew Horbowy, was
          involved in these activities. And here, too, everything turned out to
          be an artistic fantasy. What is visible there now is the ceramic
          cobalt cladding of the cinema's ground floor, which has nothing to do
          with the original design by Łukaszewicz and Horbowy.
        </p>
        <p>
          It should be added that the ceramics was ultimately put inside the
          building. However, alos this implementation was not satisfactory
          anyway. Instead of the Mieczysław Rychlicki’s design vision, the
          ticket hall and other interiors were filled with white, broken by
          workers, ceramic tiles. Another example of unfulfilled design
          aspirations at that time.
        </p>
        <p>
          The Warsaw cinema is a story of Wrocław's ambitions and
          manifestations. Its willingness to show one's place on the cultural
          map of Wrocław. Something that happened in opposition to the old,
          pre-war and German Palast-Theater. Looking at the whole – modernity
          was in the interior. The external facade is an example of the
          impossibility of realization as well as dreams of recycling and using
          glass as an element of decoration in the post-war architecture.
        </p>
      </div>
    </Puzzle>
  );
};

export default Page;
